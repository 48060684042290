import AbuBakrra from '../../assetts/images/abubakr.png';
import UmarIbnra from '../../assetts/images/umaribnhattab.png';
import Usmanibnra from '../../assetts/images/usmanra.png';
import AliIbna from '../../assetts/images/allira.png';

const caliphs = [
  {
    id: 1,
    name: 'Абу Бакр Сыддык разияллооху анху',
    period: '632-634',
    shortDescription: 'Сиддиик Акбар (Чынчылдардын улуусу)',
    fullDescription: `Абу Бакр р.а Исламды биринчи кабыл алган эркектердин бири. Ал Мекке шаарында жашап, соода менен алектенген бай жана кадырлуу адам эле. 
Пайгамбар Мухаммад с.а.в. Исламды жарыялаганда, ал дароо анын пайгамбарлыгына ишенип, Исламды кабыл алды.
\n\n632-жылы Пайгамбар Мухаммад с.а.в. дүйнөдөн кайткандан кийин, ал биринчи халиф болуп шайланган. Анын башкаруусунда Ислам жамаатын бириктирип, жалган пайгамбарлар менен күрөшкөн. Ошондой эле, ал Куранды бир китепке жыйноону баштаган. 
\n\nАбу Бакр р.а 634-жылы оорудан улам дүйнөдөн кайтып, Пайгамбар Мухаммад с.а.в. мазарында жерге берилген.`,
    imageUrl: AbuBakrra,
  },
  {
    id: 2,
    name: 'Умар ибн Хаттаб разияллооху анху',
    period: '634-644',
    shortDescription: 'Амиирул муминиин (Момундардын амири)',
    fullDescription: `Умар р.а башында Исламга жана Мухаммад с.а.в.га каршы чыккан. Ал катаал мүнөзү менен белгилүү болгон жана мусулман коомун кысмакка алган. 
Бирок, карындашынын Куран окуп жатканын көргөндө жүрөгү жумшарып, Исламды кабыл алды. Анын мусулман болушу Ислам жамаатына чоң күч-кубат берген. 
\n\n634-жылы экинчи халиф болуп шайланган Умар р.а өзүнүн башкаруусунда ислам дүйнөсүн кеңейтип, адилеттүүлүк менен башкарган. Анын тушунда Палестина, Ирак жана Египет сыяктуу жерлер Ислам халифатынын бир бөлүгүнө айланган.
\n\nУмар р.а 644-жылы намаз окуп жатканда перс кулу тарабынан бычактан жаракат алып, үч күндөн кийин дүйнөдөн кайтыш болгон.`,
    imageUrl: UmarIbnra,
  },
  {
    id: 3,
    name: 'Усман ибн Аффан разияллооху анху',
    period: '644–656 жж.',
    shortDescription: 'Зун-Нуурайн (Эки нурдун ээси)',
    fullDescription: `Усман р.а бай жана кадырлуу Курейш уруусунан чыккан. Ал Пайгамбар Мухаммад с.а.в.дын чакырыгын угуп, Исламды эрте кабыл алган. 
Ал Пайгамбардын эки кызына үйлөнүп, "Эки нурдун ээси" деген наам алган.
\n\n644-жылы үчүнчү халиф болуп шайланган Усман р.а өзүнүн башкаруусунда Ислам халифатынын аймагын кеңейткен. Ал Куранды бир нускада жыйнап, бүткүл Ислам дүйнөсүнө таратууну камсыз кылган. 
Анын башкаруусу учурунда чоң экономикалык өсүш болгон, бирок анын тушунда ички саясий чыр-чатактар да күчөгөн.
\n\n656-жылы Усман р.а өз үйүндө козголоңчулар тарабынан шейит болгон.`,
    imageUrl: Usmanibnra,
  },
  {
    id: 4,
    name: 'Али ибн Абу Талиб разияллооху анху',
    period: '656–661 жж.',
    shortDescription: 'Фаатиху Хайбар (Хайбарды жеңүүчү)',
    fullDescription: `Али р.а Пайгамбар Мухаммад с.а.в.дын кичүү аталаш бир тууганы жана күйөө баласы эле. 
Ал балалык кезинде эле Исламды кабыл алган жана Пайгамбарга биринчи ишенген жаштардын бири болгон.
\n\n656-жылы төртүнчү халиф болуп шайланган Али р.а өзүнүн башкаруусунда саясий бөлүнүү жана согуштарга туш болгон. 
Ал Ирактагы Куфа шаарын борбор кылып, Ислам коомун бириктирүүгө аракет кылган.
\n\n661-жылы таң намазга бара жатканда козголоңчулардын бири тарабынан шейит болгон.`,
    imageUrl: AliIbna,
  },
];

export default caliphs;
